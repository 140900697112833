<template>
  <section ref="section" class="-white -layer deepDive -visibility-auto">
    <parallax ref="parallax" v-if="item.type === 'video'" :from="{ y: -100 }" :to="{ y: 100 }">
      <video ref="video" autoplay playsinline loop muted slot-scope="{ parallax }" :style="`transform: translate3d(0px, ${parallax && parallax.y ? Math.round(parallax.y) : 0}px, 0px)`">
        <source ref="source" :data-src="item.video.url" type="video/mp4" />
      </video>
    </parallax>
    <parallax ref="parallax" v-if="item.type === 'image'" :from="{ y: -100 }" :to="{ y: 100 }">
      <img
        ref="picture"
        :src="imageLowDefinition + item.image.filter"
        :alt="item.image.alt || item.title || ' '"
        slot-scope="{ parallax }"
        :style="`transform: translate3d(0px, ${parallax && parallax.y ? Math.round(parallax.y) : 0}px, 0px)`"
        loading="lazy"
      />
    </parallax>
    <Observer name="fade-up" @enter="enter" @leave="leave" @enter.once="callGTM('eePromoView')">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1 text-content">
            <h2 class="a11y-sr-only" v-if="item.title" v-text="HCi18n ? $t(item.title) : item.title"></h2>
            <p class="a11y-sr-only" v-if="item.text" v-html="HCi18n ? $t(item.text) : item.text"></p>
            <div data-splitting aria-hidden="true" class="h2 -upper" v-if="item.title" v-text="HCi18n ? $t(item.title) : item.title"></div>
            <div data-splitting aria-hidden="true" class="h5 -upper wysiwyg" v-if="item.text" v-html="HCi18n ? $t(item.text) : item.text"></div>
            <template v-if="item.ctaText">
              <router-link
                v-if="!item.ctaLink.includes('http')"
                :to="localizedUrl + item.ctaLink"
                class="btn -stroke -large -white"
                v-text="HCi18n ? $t(item.ctaText) : item.ctaText"
              ></router-link>
              <a v-else :href="item.ctaLink" target="blank" class="btn -stroke -large -white" v-text="HCi18n ? $t(item.ctaText) : item.ctaText"></a>
            </template>
          </div>
        </div>
      </div>
    </Observer>
    <button v-if="item.type === 'video'" type="button" class="btn-autoPlay" :class="{ '-paused': pauseVideo }" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo"></button>
  </section>
</template>

<script>
import { Parallax } from 'leaps/dist/leaps'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import { getRouteName } from '../utilities'

export default {
  props: ['item', 'items', 'HCi18n', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  name: 'deep_dive',
  data() {
    return {
      pauseVideo: false,
      appeared: false,
    }
  },
  components: {
    Parallax,
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    imageUrl() {
      return this.item.image.url
    },
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.imageUrl, 400, 0.5636523266)
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (process.browser) {
        const Splitting = require('splitting')
        Splitting()
      }
    })
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.$refs.section.classList.remove('-visibility-auto')
        this.$refs.parallax.updateConfig()
        this.$refs.parallax.update()
        this.appeared = true
        if (this.$refs.video) {
          this.$refs.source.setAttribute('src', this.$refs.source.dataset.src)
          this.$refs.source.removeAttribute('data-src')
          setTimeout(() => {
            this.$refs.video.load()
          }, 200)
        } else {
          if (this.$refs.picture) this.$refs.picture.srcset = ImageManager.getSrcSet(this.imageUrl, 1800, 200, 100, 0.5636523266)
        }
      } else if (this.$refs.video && this.$refs.video.paused) this.$refs.video.play()
    },
    leave() {
      if (this.$refs.video && !this.$refs.video.paused) this.$refs.video.pause()
    },
    toggleVideo() {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    },
    callGTM(event, link) {
      if (getRouteName(this.$route.name) === 'home') {
        let data = {
          event: event,
          promotions: [
            {
              creative: 'section',
              id: 'sectionDeepDive_' + this.pagePosition,
              name: this.HCi18n ? this.$t(this.item.title) : this.item.title,
              position: 1,
            },
          ],
        }
        if (link) {
          data.nextPageVisited = link
        }
        this.$gtm.trackEvent(data)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  position: relative;
  padding: 0;
  overflow: hidden;
  text-align: center;
  background: black;
  transform-style: preserve-3d;
  contain-intrinsic-size: 1px calc(90vh - 120px);

  @include bp(max md) {
    min-height: calc(100vw / 1.33);
  }

  & > div {
    width: 100%;
    height: calc(90vh - 120px);
  }

  *:not(video) {
    position: relative;
    z-index: 20;
  }

  .obs-fade-up {
    transform: translateY(0);
    .btn {
      transform: translateY(4rem);
      opacity: 0;
    }
    .h2 {
      /deep/ .char {
        opacity: 0;
        transform: translateY(-6rem);
      }
    }
    .h5 {
      /deep/ .char {
        opacity: 0;
        transform: translateY(8rem);
      }
    }

    img,
    video {
      // filter: brightness(0) #{"grayscale(5)"};
      // transform: translateZ(0) scale(1.1, 1.1);
    }
  }

  .obs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .h2 {
    margin-bottom: 1rem;
    line-height: 1;

    /deep/ .word {
      overflow: hidden;
      display: inline-block;
    }
    /deep/ .char {
      display: inline-block;
      transform: translateY(0);
      @include appearTitle(200ms);
    }
  }

  .h5 {
    text-transform: uppercase;

    /deep/ .word {
      /*overflow: hidden;*/
      display: inline-block;
      vertical-align: top;
    }
    /deep/ .char {
      display: inline-block;
      vertical-align: top;
      transform: translateY(0);
      @include appearTitle(300ms);
    }

    @include bp(max md) {
      margin-bottom: 4rem;
    }
  }

  .h5 + .btn {
    margin-top: 6rem;
  }

  .btn {
    @include appear(600ms);
  }

  &.-white {
    .h2,
    .h5 {
      color: var(--zenith-component-title);
    }
  }

  video,
  img {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    // height:100%;
    height: calc(100% + 11rem);
    object-fit: cover;
    pointer-events: none;
    max-width: 100%;
    backface-visibility: hidden;
    // transform: translateZ(0) scale(1, 1);
    // filter: #{"grayscale(none)"} brightness(none);
    // @include appearImg(100ms);
  }
  .btn-autoPlay {
    position: absolute;
  }
}
</style>
